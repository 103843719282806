<template>
  <div>
    <div class="head">密码设置</div>
    <el-form
      ref="form"
      :model="form"
      label-width="100px"
    >

      <el-form-item label="当前密码">
        <el-input
          v-model="form.loginPassword"
          @change="onchange"
          show-password
        ></el-input>
        <el-link
          type="primary"
          :underline="false"
        >
          <router-link to="change_code"> 忘记密码</router-link>
        </el-link>
      </el-form-item>
      <el-form-item label="新密码">
        <el-input v-model="newloginPassword" @change="confirm" show-password></el-input>
      </el-form-item>
      <el-form-item label="确认密码">
        <el-input
          v-model="ncloginPassword"
          
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="onSubmit"
        >保存</el-button>
        <el-button>取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { QUERY, UPDATE, DELETE, INSERT } from "@/services/dao.js";
export default {
  data() {
    return {
      form: {
        id: "",
        phone: "",
        loginPassword: "",
        phonec: "",
      },
      newloginPassword: "",
      ncloginPassword: "",
      flag: true,
      flag2:true
    };
  },
  created() {
    // this.init();
    // this.refreshCode();
  },

  methods: {
    async onchange() {
      let info = await QUERY(
        "post",
        "",
        'PCUsers(where: {id: {_eq: "' +
          this.$store.state.userinfo.id +
          '"},loginPassword: {_eq: "' +
          this.$md5(this.form.loginPassword) +
          '"}}) {id      name  }'
      );
      if (info.data.PCUsers.length !== 1) {
        this.$message({
          message: "当前密码错误!",
          type: "error",
          duration: "1000",
        });
        this.flag = false;
        return false;
      }else{
        this.flag = true;
      }
    },
    confirm() {
      let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,30}$/; 
                if(!reg.test(this.newloginPassword)){
                  this.$message.warning("8位及以上密码且必须同时包含数字、字母'");
                  this.flag2= false;
                  return false;
                }else{
                  this.flag2= true;
                }
      // if (this.ncloginPassword !== this.newloginPassword) {
      //   this.$message({
      //     message: "两次输入不一致",
      //     type: "warning",
      //     duration: "1000",
      //   });
      //   return false;
      // }
    },
    async onSubmit() {
      console.log(this.form.loginPassword,this.ncloginPassword,this.newloginPassword)
      // if(this.form.loginPassword == "" || this.ncloginPassword == ""||this.newloginPassword == ""){
        
      //   this.$message.warning("请完善信息")
      //   return ;
      // }
        if(this.form.loginPassword == ""){
           this.$message.warning("当前密码不能为空")
            return ;
        }
        if(this.form.newloginPassword == ""){
           this.$message.warning("新密码不能为空!")
            return ;
        }
        if(this.form.ncloginPassword == ""){
           this.$message.warning("确认密码不能为空!")
          return ;
        }
      if(!this.flag){
        this.$message.warning("当前密码错误!");
        return;
      }
      if(!this.flag2){
        this.$message.warning("8位及以上密码且必须同时包含数字、字母'");
        return;
      }
      // console.log("22")
      if (this.ncloginPassword !== this.newloginPassword) {
        this.$message({
          message: "两次密码输入不一致,修改失败!",
          type: "warning",
          duration: "1700",
        });
        return ;
      }
      let data = await UPDATE(
        "post",
        "",
        // 'update_User(where: {id: {_eq:"' +
        //   this.$store.state.userinfo.id +
        //   '"},loginPassword: {_eq: "' +
        //   this.form.loginPassword +
        //   '"}}, _set: {loginPassword: "' +
        //   this.ncloginPassword +
        //   '"}) {     affected_rows   }'
        'update_User(where: {id: {_eq:"' +
          this.$store.state.userinfo.id +
          '"}}, _set: {loginPassword: "' +
          this.$md5(this.ncloginPassword) +
          '"}) {     affected_rows   }'
      );
      // console.log(132)
      // console.log(data)
      if (data.data.update_User.affected_rows > 0) {
        this.$message({
          message: "修改成功",
          type: "success",
          duration: "1000",
        });
        this.$router.push({ name: "login" });
      } else {
        this.$message({
          message: "修改失败",
          type: "warning",
          duration: "1000",
        });
      }
    },
    handleChange(value) {
      // console.log(value);
    },
    //验证码
  },
  mounted() {
    // this.identifyCode = "";
    // this.makeCode(this.identifyCodes, 4);
  },
};
</script>
<style scoped>
.head {
  border-bottom: 1px solid #b5b5b5;
  line-height: 50px;
  margin-bottom: 40px;
  margin-left: 15px;
  font-size: 15px;
  font-weight: 800;
}
.label {
  margin-left: -50px;
}
.el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 50%;
  margin-right: 10px;
}
.code {
  width: 124px;
  height: 31px;
  float: left;
  border: 1px solid rgba(186, 186, 186, 1);
}
.login-code {
  cursor: pointer;
}
</style>
